html,
body {
  margin: 0;
  padding: 0;
}
.fabric {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.fabric .header {
  box-sizing: border-box;
  padding: 8px;
  position: fixed;
  width: 100%;
}

.fabric .body {
  position: absolute;
  top: 56px;
  bottom: 8px;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  flex: 1;
  padding-left: 216px;
}

.fabric .navigator {
  position: absolute;
  top: 0;
  left: 0;
  width: 216px;
  height: 100%;
  box-sizing: border-box;
  padding: 8px;
  border-style: solid;
  border-color: #eee;
  border-width: 0 1px 0 0;
}

.fabric .content {
  position: relative;
  box-sizing: border-box;
  padding: 12px 20px;
  width: 100%;
  height: 100%;
  border-style: solid;
  border-color: #eee;
  border-width: 0 1px 0 0;
}

.details-list-drag-enter {
  background-color: #ccecda;
}
.details-list-disabled .ms-DetailsRow-cell,
.details-list-disabled .ms-DetailsRow:hover {
  color: rgb(163, 162, 160) !important;
  background-color: #fff !important;
}
