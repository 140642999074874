.login-page {
  text-align: center;
  margin: 0 30px;
}
.login-container {
  display: inline-flex;
  width: 100%;
  text-align: left;
  max-width: 350px;
  margin-top: 5vw;
}
.header-container {
  position: relative;
  border-style: solid;
  border-width: 0 0 1px 0;
}
.logo {
  position: relative;
  left: calc(50% * -0.09);
  width: 50%;
}
.progress {
  position: absolute;
  width: 100%;
  bottom: -3px;
}
.progress .ms-ProgressIndicator-itemProgress {
  padding: 0;
}

@media (max-width: 476px) {
  .login-page {
    margin: 0 6vw;
  }
}
